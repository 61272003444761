import React, {useEffect} from "react"
import {useStaticQuery, graphql} from "gatsby"
import Layout from "./layout"
import SEO from "./seo"
import Img from "gatsby-image"


const Page = (props) => {
  const data = useStaticQuery(graphql`
    query {
        allContentfulPages {
          edges {
            node {
              description
              markdown {
                childMarkdownRemark {
                  html
                }
              }
              title
              id
              featuredImage {
                localFile {
                  childImageSharp {
                      fluid(maxWidth: 120, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
          }
        }
    }
  `)
  const pages = data.allContentfulPages.edges
  const currentPage = pages.filter(({node: a}) => a.id === props.pageContext.page.id)[0].node
  currentPage['type'] = 'page'

  const repsonsiveIframe = () => {
    // element that will be wrapped
    let iframe = document.querySelector('.iframe-div > iframe')
    // create wrapper container
    let wrapper = document.createElement('div');
    // insert wrapper before el in the DOM tree
    iframe.parentNode.insertBefore(wrapper, iframe);
    // move iframe into wrapper
    wrapper.appendChild(iframe);
  }

  const addClass = () => {
    repsonsiveIframe()
    let wrapperDiv = document.querySelector('.iframe-div > div')
    if (currentPage.title !== 'Volunteer Form' && currentPage.title !== 'FeedBack Form') {
      wrapperDiv.classList.add('responsive-youtube')
    } else {
      wrapperDiv.classList.add('responsive-iframe')
    }
  }

  useEffect(() => {
    if (document.querySelector('.iframe-div > iframe') ) {
      addClass()
      // console.log('Found iframe and wrapped it with the div')
    }
  }, [])
  return (
    <Layout>
      <SEO schemaData={currentPage} title={currentPage.title}/>
      <div className="px-4 section">
        <h1 className=" max-w-2xl mx-auto text-3xl text-brand-main font-semibold tracking-wide leading-snug mb-6 mt-4 lg:mb-10 lg:mt-16">{currentPage.title}</h1>
        {currentPage.featuredImage && <Img fluid={currentPage.featuredImage.localFile.childImageSharp.fluid} className="max-w-2xl mx-auto mb-16"/>}
        <div className="mx-auto container text-brand-main">
          <div
            dangerouslySetInnerHTML={{__html: currentPage.markdown.childMarkdownRemark.html}}
            className="mx-auto max-w-2xl leading-relaxed tracking-wide iframe-div blog mb-4"/>
        </div>
      </div>
    </Layout>
  )
}

export default Page